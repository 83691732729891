export const calculateModalSize = size => {
    switch (size) {
        case 's':
            return '48rem';
        case 'l':
            return '134.4rem';
        case 'm':
        default:
            return '72rem';
    }
};
