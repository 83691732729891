import styled from 'styled-components';
import { colors, breakpoints } from '../../../styles/variables';

export const TemplateContainer = styled.div`
    position: relative;
    padding: 2.4rem 2.4rem .8rem;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    max-height: ${ ({ maxHeight }) => maxHeight };

    @media (min-width: ${ breakpoints.medium }) {
        padding: 3.2rem ${
            ({ size }) => size === 'l' ? '4.8rem 1.6rem' : '3.2rem .8rem' 
        };
    }
`;

export const Header = styled.div`
    display: flex;
    justify-content: space-between;
    padding-bottom: 1.6rem;
    flex-shrink: 0;

    h4 {
        flex: 1;
        margin: 0;
    }

    @media (min-width: ${ breakpoints.medium }) {
        padding-bottom: 2.4rem;
    }
`;

export const ContentContainer = styled.div`
    overflow-y: auto;
    height: fit-content;

    margin-bottom: ${ ({ withoutFooter }) => withoutFooter ? '3.2rem' : 0 };
`;

export const Content = styled.div`
    color: ${ colors.blackLowContrast };
    font-family: 'Gilroy', sans-serif;
    font-size: 1.4rem;
    font-weight: 600;
    line-height: 2rem;
    letter-spacing: -.3px;

    padding-bottom: ${ ({ withoutFooter }) => withoutFooter ? 0 : '3.2rem' };

    a {
        font-size: 1.4rem;
        line-height: 2rem;
        letter-spacing: -.3px;
    }
`;

export const Footer = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-bottom: 1.6rem;
    width: 100%;
    border-radius: 0 0 .8rem .8rem;
    flex-shrink: 0;

    &:before {
        content: "";
        position: absolute;
        top: -3.2rem;
        left: 0;
        width: 100%;
        height: 3.2rem;
        background: linear-gradient(rgba(0, 0, 0, 0), #fff);
        z-index: 5;
    }

    & > * {
        position: relative;
        z-index: 6;
    }

    @media (min-width: ${ breakpoints.medium }) {
        justify-content: flex-end;
    }
`;
