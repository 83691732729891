import styled from 'styled-components';
import { colors } from '../../../styles/variables';

export const CloseButton = styled.div`
    width: 2rem;
    height: 2rem;
    cursor: pointer;
    position: relative;

    &:before, &:after {
        position: absolute;
        content: "";
        width: .2rem;
        height: 1.7rem;
        background-color: ${ colors.black };
        top: 2px;
        right: 9px;
        border-radius: .5px;
    }

    &:before {
        transform: rotate(-45deg);
    }

    &:after {
        transform: rotate(45deg);
    }
`;
