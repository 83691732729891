import React from 'react';

import * as S from './styles';

const Notification = ({
    children,
    isVisible = false,
    size = 'm',
    position = 'bottom',
    maxHeight = '100vh',
}) => {
    return isVisible ? (
        <S.Overlay position={ position } size={ size }>
            <S.NotificationContainer
                size={ size }
                maxHeight={ maxHeight }
            >
                { children }
            </S.NotificationContainer>
        </S.Overlay>
    ) : null;
};

export default Notification;
