import React from 'react';

import { SBasicLink } from './styles';

const BasicLink = ({
    children,
    small = false,
    ...props
}) => (
    <SBasicLink
        small={ small }
        { ...props }
    >
        { children }
    </SBasicLink>
);

export default BasicLink;
