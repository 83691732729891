import React, { useState, useEffect } from 'react';
import cookie from 'react-cookies';
import { useCookiebot } from '../../../hooks/useCookiebot';
import CookiebotStyle from '../../../styles/cookiebotStyle';

import BasicLink from '../../atoms/BasicLink';
import Notification from '../../molecules/Notification';
import ModalTemplate from '../../templates/ModalTemplate';

const appCountry = process.env.GATSBY_APP_COUNTRY;

const CookieModal = ({ config = {} }) => {
    const [isNotificationVisible, setIsNotificationVisible] = useState(false);
    const [isCookiebotDisplayed, setIsCookiebotDisplayed] = useState(false);

    const notificationMaxHeight = "50vh";
    const notificationSize = "l";

    const {
        title,
        description,
        link,
    } = config;

    useEffect(() => {
        if (!!window.frameElement) {
            if ( appCountry === "PL") {
                setIsCookiebotDisplayed(true);
            } else {
            const wasCookieInfoClosed = cookie.load("pp_cookie_information");

            !!wasCookieInfoClosed || setIsNotificationVisible(true);
            }
        }
    }, []);

    const handleClose = () => {
        const currentDate = new Date();

        cookie.save(
            "pp_cookie_information",
            true,
            {
                secure: true,
                expires: new Date(currentDate.setMonth(currentDate.getMonth() + 1)),
            },
        );

        setIsNotificationVisible(false);
    };

    const COOKIE_FOOTER_CONFIG = {
        buttonsData: [
            {
                name: appCountry === "RO" ? "Respingere cookie-uri non tehnice" : "Odrzuć inne niż techniczne",
                onClick: () => handleClose(),
                type: "tertiary",
                variant: "tertiary",
                size: "s/m",
            },
            {
                name: appCountry === "RO" ? "Confirmare" : "Zaakceptuj wszystkie",
                onClick: () => handleClose(),
                size: "s/m",
            },
        ],
        buttonsAlign: "flex-end",
        reverseOnMobile: true,
    };

    useCookiebot(isCookiebotDisplayed);

    if (isCookiebotDisplayed) {
        return <CookiebotStyle />;
    }

    return (
        <Notification
            size={ notificationSize }
            position="bottom"
            maxHeight={ notificationMaxHeight }
            isVisible={ isNotificationVisible }
        >
            <ModalTemplate
                title={ title }
                titleAlign="left"
                maxHeight={ notificationMaxHeight }
                size={ notificationSize }
                handleClose={ handleClose }
                footerConfig={ COOKIE_FOOTER_CONFIG }
                withoutCloseButton
            >
                { description }

                { " " }

                <BasicLink
                    href={ link?.href }
                    target="_blank"
                >
                    { link?.text }
                </BasicLink>.
            </ModalTemplate>
        </Notification>
    );
};

export default CookieModal;
