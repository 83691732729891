import styled from "styled-components";
import { colors } from "../../../styles/variables";

export const SBasicLink = styled.a`
    color: ${ colors.paypoGreen600 };
    font-family: 'Gilroy', sans-serif;
    font-weight: 600;
    text-decoration: none;
    font-size: ${({ small }) => small ? '1.4rem' : '1.6rem'};
    line-height: ${({ small }) => small ? '2rem' : '2.4rem'};

    &:hover {
        text-decoration: underline;
        cursor: pointer;
    }
    
    &:disabled {
        color: ${ colors.paypoInactive };
        cursor: default;
    }
`;
